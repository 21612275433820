// Libraries
import * as Sentry from '@sentry/sveltekit';
import { handleErrorWithSentry } from '@sentry/sveltekit';

// Functions
import { NODE_ENV } from '$lib/settings';

Sentry.init({
  dsn: 'https://cfdb8e99933af5f57d78cc54d48f05e3@o4505394855936000.ingest.sentry.io/4506589151559680',
  tracesSampleRate: 1.0,
  environment: NODE_ENV,
  enabled: NODE_ENV === 'stage' || NODE_ENV === 'prod',

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // In development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // If the entire session is not sampled, use the below sample rate to sample
  // Sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  // If you don't want to use Session Replay, just remove the line below:
  integrations: [
    Sentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false
    })
  ]
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
