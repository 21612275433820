import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15')
];

export const server_loads = [0,2,3,4];

export const dictionary = {
		"/": [~5],
		"/change-password": [~6],
		"/login": [~7],
		"/logout": [~8],
		"/monitor/[pillar]/overview": [11,[2]],
		"/monitor/[pillar]/[group]/[indicator]": [~9,[2,3]],
		"/monitor/[pillar]/[group]/[indicator]/map/[mapType]": [~10,[2,3]],
		"/password-reset": [12,[4]],
		"/password-reset/confirm": [~14,[4]],
		"/password-reset/[uid]/[token]": [~13,[4]],
		"/terms-and-conditions": [~15]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';